<script setup lang="ts">
import { useModalStore } from '~/store/modalStore'

const modalStore = useModalStore()
const { t } = useI18n({})
const { data: postalCodes } = await useApi<{ postal_code: string }[]>('/api/products/renting-postal-codes')

function closeModal() {
  modalStore.postalCodesRentalModalShown = false
}

function stopClick(e: any) {
  e.stopPropagation()
}
</script>

<template>
  <div
    id="modal-id"
    class="min-w-screen animate-animated animate-fadeIn animate-faster flex-center fixed  inset-0 z-50 flex h-screen bg-cover bg-center bg-no-repeat outline-none focus:outline-none"
    @click="closeModal"
  >
    <div class="absolute inset-0 z-0 bg-black opacity-80" />
    <div
      class="modal  relative z-30 m-auto max-w-max rounded-xl bg-white  p-5 shadow-lg"
      @click="stopClick"
    >
      <!-- content -->
      <div class="flex-center flex flex-col">
        <!-- body -->
        <div class="flex-center flex flex-auto flex-col p-5 text-center">
          <h2 class="py-4 text-2xl font-bold ">
            {{ t('postal_codes_rental_modal.title') }}
          </h2>
          <p class="max-w-lg px-8 text-lg mb-4">
            {{ t('postal_codes_rental_modal.text') }}
          </p>
          <div class="w-full max-w-96 max-h-80 overflow-auto font-bold">
            <span
              v-for="(postalCode, index) in postalCodes"
              :key="postalCode.postal_code"
            >
              {{ index + 1 === postalCodes?.length ? `${postalCode.postal_code}` : `${postalCode.postal_code}, ` }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
